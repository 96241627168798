import { isEmpty } from "lodash";

const isTrackingEnabled = parseInt(process.env.IS_SEGMENT_TRACKING_ENABLED, 10);

export default class SegmentHelper {

    /**
     * method used to track user events using segment
     * @param {String} event 
     * @param {Object} trackedFields 
     */
    trackEvent = (event = '', trackedFields = {}) => {
        try {
            // check if segment tracking is enabled or not
            if (!isTrackingEnabled) {
                return;
            }

            if (!window.analytics) {
                return;
            }

            if (!event) {
                return;
            }

            window.analytics.track(event, { ...trackedFields });

        } catch (error) {
            console.log('Segment, event tracking failed: ', error);
        }
    }

    /**
     * method used to identify new/updated user
     * @param {Number} user
     */
    identifyUser = (user) => {
        try {
            // check if segment tracking is enabled or not
            if (!isTrackingEnabled) {
                return;
            }

            if (!window.analytics) {
                return;
            }

            if (isEmpty(user)) {
                return;
            }

            if (!user.is_loggedin) {
                return;
            }

            // check if current logged in user is the same tracked segment user or not
            if (window.analytics && window.analytics.user && window.analytics.user()
                && window.analytics.user().id() && window.analytics.user().id() == user.user_id) {
                return;
            }

            window.analytics.identify(`${user.user_id}`, {
                id: `${user.user_id}`,
                name: user.full_name,
                email: user.email,
                timestamp: new Date()
            });

        } catch (error) {
            console.log('Segment, identify user failed: ', error);
        }
    }

    /**
     * method used to identify which page the user is on
     */
    page = () => {
        try {
            // check if segment tracking is enabled or not
            if (!isTrackingEnabled) {
                return;
            }

            if (!window.analytics) {
                return;
            }

            window.analytics.page();

        } catch (error) {
            console.log('Segment, identify page failed: ', error);
        }
    }
}